"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deviceSize = void 0;
const types_1 = require("./types");
function deviceSize(width) {
    if (width > 1200)
        return types_1.DEVICE_SIZES.EXTRA_LARGE_DEVICE;
    if (width > 992)
        return types_1.DEVICE_SIZES.LARGE_DEVICE;
    if (width > 768)
        return types_1.DEVICE_SIZES.MEDIUM_DEVICE;
    if (width > 540)
        return types_1.DEVICE_SIZES.SMALL_DEVICE;
    return types_1.DEVICE_SIZES.EXTRA_SMALL_DEVICE;
}
exports.deviceSize = deviceSize;
