"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const helpers_1 = require("./helpers");
const useWindowSizes_1 = require("./useWindowSizes");
function useResponsiveStyle(styles) {
    return () => {
        const layout = (0, useWindowSizes_1.default)(helpers_1.deviceSize);
        const size = (0, helpers_1.deviceSize)(layout.width);
        return {
            styles: (style) => react_native_1.StyleSheet.compose(styles[style], styles[`${size}_${style}`]),
            deviceSize: size,
        };
    };
}
exports.default = useResponsiveStyle;
