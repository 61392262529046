import React from 'react';
import { Animated } from 'react-native';
export var createCollapsibleCustomHeaderAnimator = function (customHeader) { return function (headerProps) {
    var _a;
    return (<Animated.View style={(_a = headerProps === null || headerProps === void 0 ? void 0 : headerProps.options) === null || _a === void 0 ? void 0 : _a.headerStyle} onLayout={function (e) {
            headerProps.navigation.setParams({
                collapsibleCustomHeaderHeight: e.nativeEvent.layout.height,
            });
        }}>
    {customHeader(headerProps)}
  </Animated.View>);
}; };
